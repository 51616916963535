<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="shopId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录. 店铺id')}}<template slot="title">{{$t('商品库存记录. 店铺id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shopId"  :placeholder="$t('通用.输入.请输入')+$t('商品库存记录. 店铺id')" />
      </a-form-model-item>
      <a-form-model-item prop="goodsId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.商品编号')}}<template slot="title">{{$t('商品库存记录.商品编号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.goodsId"  :placeholder="$t('通用.输入.请输入')+$t('商品库存记录.商品编号')" />
      </a-form-model-item>
      <a-form-model-item prop="goodsName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.商品名称')}}<template slot="title">{{$t('商品库存记录.商品名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.goodsName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商品库存记录.商品名称')" />
      </a-form-model-item>
      <a-form-model-item prop="skuId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.skuid')}}<template slot="title">{{$t('商品库存记录.skuid')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.skuId"  :placeholder="$t('通用.输入.请输入')+$t('商品库存记录.skuid')" />
      </a-form-model-item>
      <a-form-model-item prop="skuName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.规格名称')}}<template slot="title">{{$t('商品库存记录.规格名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.skuName" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="orderId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.订单id')}}<template slot="title">{{$t('商品库存记录.订单id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.orderId"  :placeholder="$t('通用.输入.请输入')+$t('商品库存记录.订单id')" />
      </a-form-model-item>
      <a-form-model-item prop="type" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.变化类型：1入库；2出库')}}<template slot="title">{{$t('商品库存记录.变化类型：1入库；2出库')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="bestock" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.变化前数量')}}<template slot="title">{{$t('商品库存记录.变化前数量')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.bestock"  :placeholder="$t('通用.输入.请输入')+$t('商品库存记录.变化前数量')" />
      </a-form-model-item>
      <a-form-model-item prop="chstock" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.变化数量')}}<template slot="title">{{$t('商品库存记录.变化数量')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.chstock"  :placeholder="$t('通用.输入.请输入')+$t('商品库存记录.变化数量')" />
      </a-form-model-item>
      <a-form-model-item prop="enstock" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.变化后数量')}}<template slot="title">{{$t('商品库存记录.变化后数量')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.enstock"  :placeholder="$t('通用.输入.请输入')+$t('商品库存记录.变化后数量')" />
      </a-form-model-item>
      <a-form-model-item prop="describe" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.变动描述')}}<template slot="title">{{$t('商品库存记录.变动描述')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.describe" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商品库存记录.备注')}}<template slot="title">{{$t('商品库存记录.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSkuRecordLog, addSkuRecordLog, updateSkuRecordLog } from '@/api/goods/skuRecordLog'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        shopId: null,

        goodsId: null,

        goodsName: null,

        skuId: null,

        skuName: null,

        orderId: null,

        type: null,

        bestock: null,

        chstock: null,

        enstock: null,

        describe: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        goodsId: [
          { required: true, message: this.$t('商品库存记录.商品编号')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        goodsName: [
          { required: true, message: this.$t('商品库存记录.商品名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        skuName: [
          { required: true, message: this.$t('商品库存记录.规格名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        orderId: [
          { required: true, message: this.$t('商品库存记录.订单id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        bestock: [
          { required: true, message: this.$t('商品库存记录.变化前数量')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        chstock: [
          { required: true, message: this.$t('商品库存记录.变化数量')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        enstock: [
          { required: true, message: this.$t('商品库存记录.变化后数量')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        shopId: null,
        goodsId: null,
        goodsName: null,
        skuId: null,
        skuName: null,
        orderId: null,
        type: null,
        bestock: null,
        chstock: null,
        enstock: null,
        describe: null,
        createTime: null,
        remark: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSkuRecordLog({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateSkuRecordLog(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addSkuRecordLog(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
